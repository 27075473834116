<template>
  <div class="wrap">
    <Navbar :title="$t('Setting.FundPassword')"></Navbar>
    <div class="from">
      <div class="from_item" v-if="userInfo.setpaypass">
        <div class="title"> {{ $t('payPassword.originalPassword') }} </div>
        <van-field label-align="right" label-width="7.5rem" :border="false" colon v-model="oldpass"
          :placeholder="$t('payPassword.oldPassword')" />
      </div>
      <div class="from_item">
        <div class="title"> {{ $t('Password.setPassword') }} </div>
        <van-field label-align="right" label-width="7.5rem" :border="false" colon v-model="newpass"
          :placeholder="$t('payPassword.password')" />
      </div>
      <div class="from_item">
        <div class="title">{{ $t('Password.confirmPassword') }}</div>
        <van-field label-align="right" label-width="7.5rem" :border="false" colon v-model="confirmpass"
          :placeholder="$t('payPassword.confirmPassword')" />
      </div>
      <div class="button-confirm">
        <van-button size="normal" type="default" block @click="ButtomEvent()">{{
          $t('My.PersonalInformation.Name.Confirm')
        }}</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/JNav";
import { SETTING_PAY_PDW } from '@/api'
import { Icon, Button, Field, Toast } from 'vant'
import { mapGetters } from "vuex";

export default {
  name: 'Name',
  components: {
    [Icon.name]: Icon,
    [Button.name]: Button,
    [Field.name]: Field,
    [Toast.name]: Toast,
    Navbar
  },
  data() {
    return {
      code: '112233',
      oldpass: '',
      newpass: '',
      confirmpass: ''
    }
  },
  computed: {
    ...mapGetters(['userInfo'])
  },
  created(){
    this.$store.dispatch('getUserInfo')
  },
  methods: {
    // 返回上一个路由
    onClickLeft() {
      this.$router.go(-1)
    },
    //  提交的
    ButtomEvent() {
      if (this.userInfo.setpaypass) {
        if (!this.oldpass) return this.$toast(this.$t('payPassword.oldPassword'));
      }
      if (!this.newpass) return this.$toast(this.$t('Password.enterPassword'));
      if (!this.confirmpass) return this.$toast(this.$t('payPassword.confirmPassword'));
      if (this.newpass != this.confirmpass) return this.$toast(this.$t('Password.doubbleDiff'));
      Toast.loading({ forbidClick: true, duration: 0 });
      SETTING_PAY_PDW({
        oldpass: this.oldpass,
        newpass: this.newpass,
      }).then((res) => {
        console.log(data);

        Toast.clear();
        const { data } = res
        this.$toast(data.msg)
        if (data.ret === 1) {
          setTimeout(()=>{
            this.$router.back();
          },1000)
        }
      }).catch((e) => {
        Toast.clear();
      })
    },
  },
}
</script>

<style lang="less" scoped>
.wrap {
  width: 100%;
  height: 100%;

  .from {
    padding: calc(20rem / 16) calc(15rem / 16) calc(15rem / 16) calc(15rem / 16);

    .from_item {
      margin-bottom: calc(20rem / 16);
    }

    .title {
      font-size: calc(18rem / 16);
      font-weight: 500;
      color: var(--textColor);
      margin-bottom: calc(15rem / 16);
    }

  }

  /deep/ .van-cell {
    width: 100%;
    -webkit-appearance: none;
    appearance: none;
    background: var(--light);
    border: 1px solid transparent;
    outline: 0;
    display: block;
    box-sizing: border-box;
    height: calc(52rem / 16);
    display: flex;
    align-items: center;
    font-size: calc(16rem / 16);
    font-weight: 500;
    line-height: calc(22rem / 16);
    color: var(--light);
    border-radius: inherit;
    padding: calc(6rem / 16) calc(15rem / 16);
    box-sizing: border-box;

    input {
      color: var(--textColor);
    }

    input::placeholder {
      color: var(--colors);
    }

  }

  .button-confirm {
    width: 100%;
    height: calc(84rem / 16);
    margin-top: calc(50rem / 16);

    .van-button {
      background:var(--red);
      border: none;
      height: calc(54rem / 16);
      font-size: calc(18rem / 16);
      font-weight: 400;
      color: var(--light);
      border-radius: calc(10rem / 16);
    }
  }
}
</style>